import $ from 'jquery'
import Splide from '@splidejs/splide'
import '@splidejs/splide/dist/css/splide.min.css'
import './main.scss'

const initializeImageGallery = () => {
  document
    .querySelectorAll('.image-gallery-wrapper.splide')
    .forEach((carousel) => {
      let thumbnails
      const wrapperId = $(carousel).data('wrapper-class')
      const hasThumbnails = $(`#${wrapperId} .thumbnail-slider`).length > 0

      if (hasThumbnails) {
        thumbnails = new Splide(`#${wrapperId} .thumbnail-slider`, {
          fixedWidth: 100,
          fixedHeight: 60,
          cover: true,
          gap: 10,
          rewind: true,
          pagination: false,
          isNavigation: true,
          focus: 'center',
          breakpoints: {
            1023: {
              fixedWidth: 60,
              fixedHeight: 44,
            },
          },
        }).mount()
      }

      const paddingSizeDesktop = $(
        `#${wrapperId} .image-gallery-wrapper.splide`
      ).data('padding-size-desktop')
      const paddingSizeTablet = $(
        `#${wrapperId} .image-gallery-wrapper.splide`
      ).data('padding-size-tablet')
      const paddingSizeMobile = $(
        `#${wrapperId} .image-gallery-wrapper.splide`
      ).data('padding-size-mobile')

      const heightDesktop = $(
        `#${wrapperId} .image-gallery-wrapper.splide`
      ).data('height-desktop')
      const heightTablet = $(
        `#${wrapperId} .image-gallery-wrapper.splide`
      ).data('height-tablet')
      const heightMobile = $(
        `#${wrapperId} .image-gallery-wrapper.splide`
      ).data('height-mobile')

      const autoPlay = $(`#${wrapperId} .image-gallery-wrapper.splide`).data(
        'auto-play'
      )

      const main = new Splide(
        `#${wrapperId} .section--image-gallery .main-slider`,
        {
          type: 'slide',
          rewind: true,
          focus: 'center',
          fixedHeight: heightDesktop,
          speed: 800,
          cover: true,
          pagination: false,
          autoplay: autoPlay,
          easing: 'linear',
          padding: {
            right: paddingSizeDesktop,
            left: paddingSizeDesktop,
          },
          breakpoints: {
            1023: {
              fixedHeight: heightTablet,
              padding: {
                right: paddingSizeTablet,
                left: paddingSizeTablet,
              },
            },
            767: {
              fixedHeight: heightMobile,
              padding: {
                right: paddingSizeMobile,
                left: paddingSizeMobile,
              },
            },
          },
        }
      )

      main.mount()

      if (hasThumbnails) {
        thumbnails.on('click', (slide, e) => main.go(slide.index))

        return main.on('moved', (newIndex, _prevIndex) =>
          thumbnails.go(newIndex)
        )
      }
    })
}

$(document).on('simplero:section:load', initializeImageGallery)
$(initializeImageGallery)
